import React, {useEffect} from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import BeforeAfterComponent from '../components/BeforeAfter/BeforeAfterComponent';

const BeforeAfter2 = ({ setButtonShow, setButtonText, setButtonTime, setEventProperties, sessionId, setEventName}) => {
    useEffect(() => {
        setButtonShow(true);
        setButtonText('Continue');
        setButtonTime(150)
        setEventName('web_analytics')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle title="Our AI runs A/B tests to find the winning creatives" />
            <p style={{ marginBottom: "24rem" }} className="text">With your star performers, you can get more clicks and even more traffic for your next ads</p>
            <BeforeAfterComponent
                beforeTitle='First version'
                beforeImg="before3.webp"
                beforeText1={(
                    <> 
                        <p style={{margin: "0 auto"}}>
                        New sales:
                            <span>113</span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7989_58828)">
                                    <path d="M4.64645 9.35355C4.84171 9.54882 5.15829 9.54882 5.35355 9.35355L8.53553 6.17157C8.7308 5.97631 8.7308 5.65973 8.53553 5.46447C8.34027 5.2692 8.02369 5.2692 7.82843 5.46447L5 8.29289L2.17157 5.46447C1.97631 5.2692 1.65973 5.2692 1.46447 5.46447C1.2692 5.65973 1.2692 5.97631 1.46447 6.17157L4.64645 9.35355ZM4.5 1L4.5 9L5.5 9L5.5 1L4.5 1Z" fill="#EF2C4F" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7989_58828">
                                        <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </p>
                    </>
                )}
                afterTitle="Second version"
                afterImg="after3.webp"
                afterText1={(
                    <>
                    <p style={{margin: "0 auto"}}>
                    New sales:
                        <span>148</span>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_58851)">
                                <path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_58851">
                                    <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </p>
                    {/* <p>
                        ROAS:
                        <span>{afterText1}</span>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_58851)">
                                <path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_58851">
                                    <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>


                    </p> */}
                    </>
                )}
                
            ></BeforeAfterComponent>
           
        </div>
    );
}

export default BeforeAfter2;
