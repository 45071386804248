import React, { useEffect, useState } from 'react';
import LogoDark from '../components/Logo/LogoDark';
import CustomTitle from '../components/UI/title/CustomTitle';
import CustomRadio from '../components/UI/radio/CustomRadio';
import { WithWizard } from 'react-albus-react18';
import CustomCheckbox from '../components/UI/chekbox/CustomCheckbox';
import { TypeAnimation } from 'react-type-animation';

const Goal = ({  setButtonText, setCheckboxes, setError, error, selectedOption, setSelectedOption, setButtonShow, setButtonTime, setAiShow, setButtonStatus, setBlockPaddingBottom,setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonText('Continue')
        setButtonShow(true);
        setButtonTime(0);
        setSelectedOption(null)
        setAiShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setCheckboxes({
            asdas: true,
        });
        setEventName('web_goals')
    }, []);

    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "Increase brand recognition",
            img: 'personal.svg',
        },
        {
            id: 'option2',
            checked: false,
            text: "Create winning ad creatives",
            img: 'creatives.svg',
        },
        {
            id: 'option3',
            checked: false,
            text: "Boost business profitability",
            img: 'promotion.svg',
        },
        {
            id: 'option4',
            checked: false,
            text: "Receive more online orders",
            img: 'shop.svg',
        },
        {
            id: 'option5',
            checked: false,
            text: "Launch effective online ads",
            img: 'launch.svg',
        }
    ];



    // const handleCheckboxChange = (checkbox, nextFunction) => {


    //     setSelectedOption(checkbox.id);

    //     var profilesJSON = localStorage.getItem('business-profiles');
    //     if (profilesJSON) {
    //         var profiles = JSON.parse(profilesJSON);
    //         profiles.forEach(function(profile) {
    //             profile.goal = checkbox.text;
    //         });
    //         var updatedProfilesJSON = JSON.stringify(profiles);
    //         localStorage.setItem('business-profiles', updatedProfilesJSON);
    //     } else {
    //         var newProfile = {
    //             goal: checkbox.text
    //         };
    //         var newProfiles = [newProfile];
    //         var newProfilesJSON = JSON.stringify(newProfiles);
    //         localStorage.setItem('business-profiles', newProfilesJSON);
    //     }

    //     setTimeout(() => {
    //         nextFunction();
    //     }, 350);

    //     setError(false);
    // };
    const [showTyping, setShowTyping] = useState(false);
    const [checkboxState, setCheckboxState] = useState(initialCheckboxState);

    const handleCheckboxChange = (id, isChecked) => {
        setCheckboxState(prevState =>
            prevState.map(checkbox =>
                checkbox.id === id ? { ...checkbox, checked: isChecked } : checkbox
            )
        );
    };



    useEffect(() => {
        const checkedOptions = checkboxState.filter(option => option.checked === true);
        const checkedTexts = checkedOptions.map(option => option.text);


        if (checkedTexts.length > 0) {
            setSelectedOption('1')
            setShowTyping(true)
        } else {
            setSelectedOption(null)
            setShowTyping(false)
        }
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.goal = checkedTexts.join(', ');
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                goal: checkedTexts.join(', ')
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_maingoal: checkedTexts.join(', ')
        })

    }, [checkboxState]);



    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div >
            <CustomTitle style={{ marginBottom: "12rem" }} title="What are your main goals?" />
            <p style={{ marginBottom: "16rem" }} className="text">
            Select one or more goals and AI will create a custom plan to help you achieve them
            </p>
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        {checkboxState.map((checkbox) => (

                            <CustomCheckbox
                                key={checkbox.id}
                                style={{ height: '42rem' }}
                                checked={checkbox.checked}
                                onChange={(isChecked) => handleCheckboxChange(checkbox.id, isChecked)}
                                className={error ? 'error' : undefined}
                            >

                                <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} style={{ minWidth: '20rem', width: "20rem", height: "20rem", marginRight: '20rem', borderRadius: '0' }} alt="" />
                                {checkbox.text}
                            </CustomCheckbox>

                            // <CustomRadio
                            //     key={checkbox.id}
                            //     id={checkbox.id}
                            //     checked={selectedOption === checkbox.id}
                            //     onChange={() => handleCheckboxChange(checkbox, next)}
                            //     className={error ? 'error' : undefined}
                            //     style={{ marginBottom: '20rem' }}
                            // >
                            //     <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} style={{ minWidth: '20rem', width: "20rem", height: "20rem", borderRadius: '0' }} alt="" />
                            //     {checkbox.text}
                            // </CustomRadio>
                        ))}
                    </>
                )}
            />

            {showTyping && (
                <div className="typing-block" style={{ marginTop: '16rem', marginBottom: '7rem', minHeight: typeHeight }}>

                    <TypeAnimation
                        sequence={[
                            'Thanks for sharing! Our AI will focus on your main goals when creating your custom promotion plan.',
                        ]}
                        wrapper="span"
                        speed={80}
                        style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                        repeat={1}
                        cursor={false}
                    />

                </div>

            )}
            <div style={{ paddingBottom: "25rem" }}></div>
        </div >
    );
}

export default Goal;
