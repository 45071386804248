import React, { useState, useEffect, useRef, useCallback } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './trial.scss';
import { WithWizard } from 'react-albus-react18';
import MyButton from '../../components/UI/button/MyButton';
import axios from 'axios';
import Payment, { InitConfig, SdkMessage, ClientSdkInstance, MessageType } from "@solidgate/react-sdk";
import { Helmet } from "react-helmet";
import PriceInfo from '../../components/PriceInfo/PriceInfo';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const formParams = {
    allowSubmit: false,
    formTypeClass: 'card',
    googleFontLink: 'https://fonts.googleapis.com/css2?family=Public+Sans:ital@0;1&display=swap',
};

const Trial = ({ setShowTermBundle, setButtonShow, setWhiteWrapper, setWelcometo, setTrialLoad, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();
    useEffect(() => {
        setButtonShow(false);
        setWhiteWrapper(true);
        setWelcometo(false);
    }, [history.location]);

    const [isUSA, setIsUSA] = useState(false);

    useEffect(() => {
        // const fetchIPInfo = async () => {
        //     try {
        //         const response = await fetch('https://ipapi.co/json/');
        //         const data = await response.json();
        //         setIsUSA(data.country === 'US');

        //     } catch (error) {
        //         console.error('Error fetching IP info:', error);
        //     }
        // };
        // fetchIPInfo();
    }, []);

    const [yourPrice, setYourPrice] = useState('');
    const [week, setWeek] = useState('2');
    const [discount, setDiscont] = useState('');
    const [total, setTotal] = useState('');
    const [precent, setPrecent] = useState('');
    const [price, setPrice] = useState('89.95');
    const [productId, setProductId] = useState('fda854e8-a1d3-4720-a595-7bb3193c1755');

    useEffect(() => {

        if (localStorage.getItem("price")) {
            if (localStorage.getItem('price') == 'growth') {
                setWeek('2')
                setYourPrice("42.00")
                setDiscont('22.00')
                setTotal('19.95')
                setPrecent("53")
                setProductId('7694fbac-dc3f-4607-94a2-5fa935e7b326')
            }
            if (localStorage.getItem('price') == 'plus') {
                setWeek('4')
                setYourPrice("83.00")
                setDiscont('53.00')
                setTotal('29.95')
                setPrecent("64")
                setProductId('fda854e8-a1d3-4720-a595-7bb3193c1755')
            }
            if (localStorage.getItem('price') == 'starter') {
                setWeek('8')
                setYourPrice("167.00")
                setDiscont('117.00')
                setTotal('49.95')
                setPrecent("74")
                setProductId('d0215e57-181e-419d-85a1-13f9c96e8364')
            }
        }



    }, [localStorage.getItem("price")]);




    const [buttonText, setButtonText] = useState('Pay with');
    const [buttonIcon, setButtonIcon] = useState('applePay');

    const [selectedMethod, setSelectedMethod] = useState('applePay'); // Default to 'applePay'

    const handleSelectMethod = (method) => {
        setSelectedMethod(method);
        if (method === 'applePay') {
            setButtonText('Pay with')
            setButtonIcon('applePay')
        }
        if (method === 'creditCard') {
            setButtonText('Pay  1.00  USD')
            setButtonIcon('')
        }
    };

    const [merchant, setMerchant] = useState('');
    const [signature, setSignature] = useState('');
    const [paymentIntent, setPaymentIntent] = useState('');
    const [scriptUrl, setScriptUrl] = useState('');
    const merchantData = {
        merchant: merchant,
        signature: signature,
        paymentIntent: paymentIntent
    }

    const [currentProductId, setCurrentProductId] = useState("");
    useEffect(() => {
        if (currentProductId != productId) {
            setCurrentProductId(productId)
            if (localStorage.getItem("email")) {
                initSolidgate();
                initPaypal()
            }
        }
    }, [price, productId]);


    const appleContainerRef = useRef(null)

    const [isMounted, setIsMounted] = useState(false);
    const [form, setForm] = useState(null);

    const handleOnReadyPaymentInstance = useCallback((form) => {
        setForm(form);
    }, []);

    const handleMounted = useCallback(() => {
        setIsMounted(true);
    }, []);



    const handleSubmit = useCallback((event) => {

        console.log(event)
        // mixpanel.track("web_trial", {
        //     session_id: sessionId,
        //     user_id: localStorage.getItem('userId'),
        //     web_pay_method: 'Paypal'
        // });
    }, [])


    const handleSuccess = useCallback((event) => {
        console.log(event)

        if (localStorage.getItem("price")) {
            if (localStorage.getItem('price') == 'growth') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'purchase',
                    ecommerce: {
                        transaction_id: event.order.order_id,
                        value: '19.95',
                        currency: "USD",
                        items: [
                            {
                                item_id: '7694fbac-dc3f-4607-94a2-5fa935e7b326',
                                discount: '22.00',
                                item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                item_variant: `2-week plan`,
                                price: "42.00",
                                quantity: 1
                            }
                        ]
                    },
                });
            }
            if (localStorage.getItem('price') == 'plus') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'purchase',
                    ecommerce: {
                        transaction_id: event.order.order_id,
                        value: '29.95',
                        currency: "USD",
                        items: [
                            {
                                item_id: 'fda854e8-a1d3-4720-a595-7bb3193c1755',
                                discount: '53.00',
                                item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                item_variant: `4-week plan`,
                                price: "83.00",
                                quantity: 1
                            }
                        ]
                    },
                });
            }
            if (localStorage.getItem('price') == 'starter') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'purchase',
                    ecommerce: {
                        transaction_id: event.order.order_id,
                        value: '49.95',
                        currency: "USD",
                        items: [
                            {
                                item_id: 'd0215e57-181e-419d-85a1-13f9c96e8364',
                                discount: '117.00',
                                item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                item_variant: `8-week plan`,
                                price: "167.00",
                                quantity: 1
                            }
                        ]
                    },
                });
            }
        }


        history.push('/loader')

    }, [])

    const initSolidgate = () => {
        console.log("init solidgate");
        // const fullJSON = localStorage.getItem('business-profiles');
        // const profile = JSON.parse(fullJSON)[0];
        setTrialLoad(true)
        axios.post("https://staging.zeely.link/stripe/solidgate", {
            email: localStorage.getItem('email'),
            productId: productId,
        })
            .then((response) => {
                if (response) {
                    setMerchant(response.data.merchant);
                    setSignature(response.data.signature)
                    setPaymentIntent(response.data.paymentIntent)
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                // Handle error here
            });
    };

    const initPaypal = () => {

        // const fullJSON = localStorage.getItem('business-profiles');
        // const profile = JSON.parse(fullJSON)[0];

        axios.post("https://staging.zeely.link/subscriptions/solidgate/init", {
            email: localStorage.getItem('email'),
            productId: productId,
        })
            .then((response) => {
                console.log(response)
                if (response) {
                    localStorage.setItem('orderIdPaypal', response.data.orderId)
                    setScriptUrl(response.data.scriptUrl)
                }
                setTrialLoad(false)
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setTrialLoad(false)
                // Handle error here
            });
    };


    const styles = {
        form_body: {
            "width": "100%",
            "min-width": "0",
        },
        card_view: {
            "background": "#fff",
            "box-shadow": "none",
            "border-radius": "0",
            "padding": "0",
            "margin-bottom": '0px',
            ".brand-icon": {
                "display": "none"
            },
        },
        card_number: {
            '.error input': {
                'border-color': '#FC9494',
                color: '#FC9494'
            },
            '.error .label': {
                color: '#FC9494!important',
            },
            '.error-text': {
                color: '#FC9494',
                position: "absolute",
                background: "#ffffff",
            },
            input: {
                background: "transparent!important",
                "font-family": '"Public Sans", sans-serif!important',
                border: "none",
                "font-weight": " 400!important",
                "text-transform": "uppercase",
                color: "#000!important",
                width: "100%",
                "padding": '0!important',
                height: '17px',
                "font-size": "14px!important",
                "border-radius": '0',
                "::placeholder": {
                    "font-size": "14px!important",
                    color: "#A7A8AA!important",
                }
            },
            ".input-block": {
                "border": "1px solid #F2F4F6",
                "border-radius": "11px",
                "padding": "24px 16px 8px",
            },
            '.label': {
                "font-family": '"Public Sans", sans-serif!important',
                "font-weight": "400",
                color: "#000!important",
                "left": "16px",
                top: '8px',
                "padding-left": '0',
                "font-size": "10px!important",
                "line-height": "10px",
                "opacity": "1!important",
            },

        },
        card_cvv: {
            '.error input': {
                'border-color': '#FC9494',
                color: '#FC9494'
            },
            '.error .label': {
                color: '#FC9494!important',
            },
            '.error-text': {
                color: '#FC9494',
                position: "absolute",
                background: "#ffffff",
            },
            input: {
                background: "transparent!important",
                "font-family": '"Public Sans", sans-serif!important',
                border: "none",
                "font-weight": " 400!important",
                "text-transform": "uppercase",
                color: "#000!important",
                width: "100%",
                "padding": '0!important',
                height: '17px',
                "font-size": "14px!important",
                "border-radius": '0',
                "::placeholder": {
                    "font-size": "14px!important",
                    color: "#A7A8AA!important",
                }
            },
            ".input-block": {
                "border": "1px solid #F2F4F6",
                "border-radius": "11px",
                "padding": "24px 16px 8px",
            },
            '.label': {
                "font-family": '"Public Sans", sans-serif!important',
                "font-weight": "400",
                color: "#000!important",
                "left": "16px",
                top: '8px',
                "padding-left": '0',
                "font-size": "10px!important",
                "line-height": "10px",
                "opacity": "1!important",
            },
            ".tooltip": {
                width: "136px",
                "min-height": "56px",
                top: "auto",
                bottom: "3em",
                img: {
                    width: "100px",
                },
                "::after": {
                    top: "auto",
                    bottom: "-17px",
                    right: "calc(0.9em + -23px)",
                    transform: "rotate(-225deg)",
                }
            },
            ".tooltip-icon": {
                "::before": {
                    "background-color": "#ABABAB",
                    color: '#fff'
                }
            },

        },
        expiry_date: {
            '.error input': {
                'border-color': '#FC9494',
                color: '#FC9494'
            },
            '.error .label': {
                color: '#FC9494!important',
            },
            '.error-text': {
                color: '#FC9494',
                position: "absolute",
                background: "#ffffff",
            },
            input: {
                background: "transparent!important",
                "font-family": '"Public Sans", sans-serif!important',
                border: "none",
                "font-weight": " 400!important",
                "text-transform": "uppercase",
                color: "#000!important",
                width: "100%",
                "padding": '0!important',
                height: '17px',
                "font-size": "14px!important",
                "border-radius": '0',
                "::placeholder": {
                    "font-size": "14px!important",
                    color: "#A7A8AA!important",
                }
            },
            ".input-block": {
                "border": "1px solid #F2F4F6",
                "border-radius": "11px",
                "padding": "24px 16px 8px",
            },
            '.label': {
                "font-family": '"Public Sans", sans-serif!important',
                "font-weight": "400",
                color: "#000!important",
                "left": "16px",
                top: '8px',
                "padding-left": '0',
                "font-size": "10px!important",
                "line-height": "10px",
                "opacity": "1!important",
            },
        },
    }


    const [platfrom, setPlatfrom] = useState('');

    const isApplePayAvailable = () => {
        if (window.ApplePaySession) {

            return true;
        } else {

            return false;
        }
    }

    useEffect(() => {
        if (isApplePayAvailable()) {
            // setSelectedMethod('applePay')
            // setPlatfrom('iOS')
            setSelectedMethod('')
            setPlatfrom('')
        } else {
            // setSelectedMethod('creditCard')
            setPlatfrom('')
        }
    }, []);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('android')) {
            setPlatfrom('Android')
            if (!isUSA) {
                setSelectedMethod('creditCard')
            } else {
                setSelectedMethod('applePay')
            }
        } else if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
            setPlatfrom('iOS')
        } else {
            setPlatfrom('')
            if (!isUSA) {
                setSelectedMethod('creditCard')
            } else {
                setSelectedMethod('applePay')
            }
        }
    }, [isUSA]);

    const initialOptions = {
        "client-id": "YOUR-CLIENT-ID-HERE",
        currency: "USD",
        intent: "capture",
    };

    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = scriptUrl;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            const elem = document.getElementById('paypal-button');

            const handleOrderStartedProcessing = (e) => {

                console.log('order-started-processing', e);

            };

            const handleOrderProcessed = (e) => {
                console.log('order-processed', e.detail);
                if (e.detail.data.error) {
                    console.log('Error:', e.detail.data.error);
                    setShowErrors(true)
                    setTimeout(() => {
                        setShowErrors(false)
                    }, 2000);
                } else {
                    if (e.detail.data.order.status) {
                        if (e.detail.data.order.status === "approved") {
                            mixpanel.track("web_trial", {
                                session_id: sessionId,
                                user_id: localStorage.getItem('userId'),
                                web_pay_method: 'Paypal',
                                web_utm_campaign: localStorage.getItem("utmCampaign"),
                                web_utm_source: localStorage.getItem("utmSource"),
                                web_utm_medium: localStorage.getItem("utmMedium"),
                                web_utm_content: localStorage.getItem("utmContent"),
                                web_utm_term: localStorage.getItem("utmTerm"),
                                isDebug: false,
                                funnel: 'MAIN_v1',
                            });
                            localStorage.setItem('web_pay_method', 'Paypal')
                            if (localStorage.getItem("price")) {
                                if (localStorage.getItem('price') == 'growth') {
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'event': 'purchase',
                                        ecommerce: {
                                            transaction_id: localStorage.getItem('orderIdPaypal'),
                                            value: '19.95',
                                            currency: "USD",
                                            items: [
                                                {
                                                    item_id: '7694fbac-dc3f-4607-94a2-5fa935e7b326',
                                                    discount: '22.00',
                                                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                                    item_variant: `2-week plan`,
                                                    price: "42.00",
                                                    quantity: 1
                                                }
                                            ]
                                        },
                                    });
                                }
                                if (localStorage.getItem('price') == 'plus') {
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'event': 'purchase',
                                        ecommerce: {
                                            transaction_id: localStorage.getItem('orderIdPaypal'),
                                            value: '29.95',
                                            currency: "USD",
                                            items: [
                                                {
                                                    item_id: 'fda854e8-a1d3-4720-a595-7bb3193c1755',
                                                    discount: '53.00',
                                                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                                    item_variant: `4-week plan`,
                                                    price: "83.00",
                                                    quantity: 1
                                                }
                                            ]
                                        },
                                    });
                                }
                                if (localStorage.getItem('price') == 'starter') {
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'event': 'purchase',
                                        ecommerce: {
                                            transaction_id: localStorage.getItem('orderIdPaypal'),
                                            value: '49.95',
                                            currency: "USD",
                                            items: [
                                                {
                                                    item_id: 'd0215e57-181e-419d-85a1-13f9c96e8364',
                                                    discount: '117.00',
                                                    item_name: localStorage.getItem('direction') ? localStorage.getItem('direction').toLowerCase() : 'product',
                                                    item_variant: `8-week plan`,
                                                    price: "167.00",
                                                    quantity: 1
                                                }
                                            ]
                                        },
                                    });
                                }
                            }
                            history.push('/loader')

                        }
                    }
                }

            };

            const handleOrderAlreadyProcessed = (e) => {
                console.log('order-already-processed', e);
            };

            const handleButtonReady = (e) => {
                console.log('button-ready', e);
            };

            const handleButtonError = (e) => {
                console.log('button-error', e);
            };

            elem.addEventListener('order-started-processing', handleOrderStartedProcessing);
            elem.addEventListener('order-processed', handleOrderProcessed);
            elem.addEventListener('order-already-processed', handleOrderAlreadyProcessed);
            elem.addEventListener('button-ready', handleButtonReady);
            elem.addEventListener('button-error', handleButtonError);

            // Clean up event listeners when the component unmounts
            return () => {
                elem.removeEventListener('order-started-processing', handleOrderStartedProcessing);
                elem.removeEventListener('order-processed', handleOrderProcessed);
                elem.removeEventListener('order-already-processed', handleOrderAlreadyProcessed);
                elem.removeEventListener('button-ready', handleButtonReady);
                elem.removeEventListener('button-error', handleButtonError);
            };
        };

        // Clean up function for script removal
        return () => {
            document.body.removeChild(script);
        };
    }, [scriptUrl]);

    const [checkboxes, setCheckboxes] = useState({
        Facebook: false,
    });
    const [error, setError] = useState(false);
    const areAllCheckboxesUnchecked = () => {
        return Object.values(checkboxes).every((isChecked) => !isChecked);
    };


    const [promocode, setPromocode] = useState('');
    const [promocodeCurrent, setPromocodeCurrent] = useState('');

    const handleInputChange = (event) => {
        setPromocode(event.target.value);
        if (event.target.value == "") {
            setCheckboxes({ F: false, })
        } else {
            setCheckboxes({ F: true, })
        }
    };


    const [buttonStatus, setButtonStatus] = useState('');

    const [showPromocodePopup, setShowPromocodePopup] = useState(false);
    const handleClick = () => {
        if (areAllCheckboxesUnchecked()) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1000);
        } else {
            setError(false);
            setButtonStatus("load")

            setTimeout(() => {
                setPromocodeCurrent(promocode)
                setShowPromocodePopup(false)
            }, 2000);
        }
    };



    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }} className='trial'>
            <Helmet>
                <meta name="theme-color" content="#fff"></meta>
            </Helmet>
            <CustomTitle style={{ marginBottom: "24rem" }} title="Select payment method" />
            {/* <p style={{ marginBottom: "24px" }} className="text">
                It's important for us to keep your account
                secure in very safe place
            </p> */}

            {(isUSA || platfrom == 'iOS') &&
                (
                    <div className="trial-method__options">
                        <div
                            className={`trial-method__option ${selectedMethod === 'applePay' ? 'trial-method__option--active' : ''}`}
                            onClick={() => handleSelectMethod('applePay')}
                        >

                            {isUSA && (
                                <svg style={{ marginBottom: platfrom == 'iOS' ? '8rem' : '' }} width="76" height="20" viewBox="0 0 76 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_9469_25891)">
                                        <path d="M24.4031 4.00195C24.2479 4.00195 24.116 4.11467 24.0917 4.26814L22.2945 15.6654C22.288 15.707 22.2906 15.7495 22.3021 15.7901C22.3135 15.8306 22.3337 15.8682 22.3611 15.9002C22.3885 15.9323 22.4224 15.958 22.4607 15.9756C22.499 15.9932 22.5406 16.0024 22.5828 16.0024H24.7141C24.8691 16.0024 25.0012 15.8894 25.0255 15.7362L25.5534 12.3893C25.5775 12.2361 25.7096 12.1231 25.8648 12.1231H27.8026C30.0666 12.1231 31.9886 10.4712 32.3396 8.23272C32.6936 5.97533 30.9301 4.00685 28.4305 4.00195H24.4031ZM26.475 6.54625H28.0253C29.3017 6.54625 29.7174 7.29938 29.586 8.13226C29.4545 8.96692 28.809 9.58172 27.5743 9.58172H25.9963L26.475 6.54625ZM36.6744 7.32099C36.1363 7.32299 35.517 7.43326 34.8218 7.72284C33.2271 8.38753 32.4613 9.76126 32.136 10.7628C32.136 10.7628 31.1009 13.8183 33.4396 15.497C33.4396 15.497 35.6086 17.113 38.0502 15.3974L38.0081 15.6654C38.0015 15.707 38.0041 15.7495 38.0155 15.79C38.027 15.8305 38.0471 15.868 38.0744 15.9001C38.1018 15.9321 38.1357 15.9578 38.1739 15.9755C38.2122 15.9931 38.2537 16.0023 38.2959 16.0024H40.3191C40.4744 16.0024 40.6063 15.8894 40.6305 15.7362L41.8613 7.93133C41.8679 7.88971 41.8654 7.84714 41.8539 7.80657C41.8425 7.766 41.8224 7.72839 41.7951 7.69632C41.7677 7.66425 41.7338 7.63848 41.6955 7.6208C41.6572 7.60311 41.6156 7.59392 41.5735 7.59386H39.5502C39.3949 7.59386 39.2628 7.7068 39.2388 7.86005L39.1726 8.28017C39.1726 8.28017 38.2892 7.31497 36.6744 7.32099ZM36.7406 9.78621C36.9729 9.78621 37.1854 9.81828 37.3761 9.87954C38.2491 10.1598 38.744 10.9984 38.6008 11.9075C38.4242 13.0268 37.5062 13.851 36.3289 13.851C36.0968 13.851 35.8843 13.8192 35.6934 13.7579C34.8207 13.4777 34.3226 12.639 34.4658 11.73C34.6425 10.6106 35.5633 9.78621 36.7406 9.78621Z" fill="#003087" />
                                        <path d="M53.5379 4.00195C53.3826 4.00195 53.2508 4.11467 53.2265 4.26814L51.4293 15.6654C51.4228 15.707 51.4253 15.7495 51.4368 15.7901C51.4483 15.8306 51.4684 15.8682 51.4958 15.9002C51.5232 15.9323 51.5572 15.958 51.5955 15.9756C51.6338 15.9932 51.6754 16.0024 51.7176 16.0024H53.8489C54.0039 16.0024 54.136 15.8894 54.1603 15.7362L54.6882 12.3893C54.7123 12.2361 54.8444 12.1231 54.9996 12.1231H56.9373C59.2014 12.1231 61.1231 10.4712 61.474 8.23272C61.8281 5.97533 60.0648 4.00685 57.5653 4.00195H53.5379ZM55.6097 6.54625H57.1601C58.4365 6.54625 58.8521 7.29938 58.7207 8.13226C58.5893 8.96692 57.944 9.58172 56.709 9.58172H55.131L55.6097 6.54625ZM65.809 7.32099C65.2708 7.32299 64.6515 7.43326 63.9563 7.72284C62.3616 8.38753 61.5958 9.76126 61.2706 10.7628C61.2706 10.7628 60.2359 13.8183 62.5746 15.497C62.5746 15.497 64.7431 17.113 67.1849 15.3974L67.1428 15.6654C67.1363 15.707 67.1389 15.7495 67.1503 15.7901C67.1618 15.8306 67.182 15.8682 67.2093 15.9002C67.2367 15.9323 67.2707 15.958 67.309 15.9756C67.3473 15.9932 67.3889 16.0024 67.4311 16.0024H69.4541C69.6094 16.0024 69.7413 15.8894 69.7655 15.7362L70.9967 7.93133C71.0033 7.88967 71.0008 7.84707 70.9893 7.80647C70.9779 7.76586 70.9578 7.72823 70.9304 7.69615C70.903 7.66407 70.8689 7.63832 70.8306 7.62066C70.7923 7.603 70.7506 7.59386 70.7085 7.59386H68.6852C68.5299 7.59386 68.3978 7.7068 68.3738 7.86005L68.3076 8.28017C68.3076 8.28017 67.4237 7.31497 65.809 7.32099ZM65.8751 9.78621C66.1075 9.78621 66.32 9.81828 66.5107 9.87954C67.3836 10.1598 67.8786 10.9984 67.7354 11.9075C67.5587 13.0268 66.6407 13.851 65.4635 13.851C65.2314 13.851 65.0189 13.8192 64.828 13.7579C63.9552 13.4777 63.4571 12.639 63.6004 11.73C63.777 10.6106 64.6979 9.78621 65.8751 9.78621Z" fill="#0070E0" />
                                        <path d="M42.8209 7.59397C42.6605 7.59397 42.5465 7.75057 42.5955 7.90316L44.8136 14.7865L42.808 18.0302C42.7106 18.1877 42.8238 18.3911 43.0089 18.3911H45.3794C45.4472 18.391 45.5139 18.3735 45.5729 18.3402C45.6319 18.3068 45.6814 18.2589 45.7165 18.2009L51.9115 7.95261C52.0066 7.79512 51.893 7.59375 51.709 7.59375H49.3387C49.2702 7.59381 49.203 7.61169 49.1435 7.64563C49.0841 7.67957 49.0345 7.7284 48.9996 7.78732L46.5611 11.9043L45.3233 7.81784C45.283 7.68486 45.1603 7.59375 45.0215 7.59375L42.8209 7.59397Z" fill="#003087" />
                                        <path d="M73.2962 4.00195C73.1412 4.00195 73.0091 4.11489 72.9848 4.26814L71.1872 15.6649C71.1806 15.7066 71.1831 15.7492 71.1946 15.7898C71.206 15.8304 71.2261 15.868 71.2535 15.9001C71.2809 15.9322 71.3149 15.9579 71.3532 15.9756C71.3916 15.9932 71.4332 16.0024 71.4754 16.0024H73.6067C73.7618 16.0024 73.8939 15.8894 73.9182 15.7362L75.7153 4.33898C75.7219 4.29738 75.7193 4.25487 75.7079 4.21435C75.6964 4.17384 75.6763 4.13628 75.649 4.10427C75.6216 4.07225 75.5877 4.04652 75.5494 4.02886C75.5112 4.0112 75.4696 4.00202 75.4275 4.00195H73.2962Z" fill="#0070E0" />
                                        <path d="M6.63453 4.00195C6.49724 4.00207 6.36449 4.05114 6.26013 4.14035C6.15577 4.22956 6.08663 4.35307 6.06517 4.48867L5.11914 10.4888C5.16325 10.2085 5.4047 10.0021 5.68849 10.0021H8.46067C11.2507 10.0021 13.6181 7.96675 14.0507 5.20861C14.0829 5.00269 14.1011 4.79484 14.1053 4.58646C13.3962 4.21468 12.5633 4.00195 11.6507 4.00195H6.63453Z" fill="#001C64" />
                                        <path d="M14.1039 4.58594C14.0998 4.79431 14.0815 5.00217 14.0493 5.20809C13.6167 7.96623 11.2491 10.0015 8.45932 10.0015H5.68715C5.40358 10.0015 5.1619 10.2078 5.11779 10.4883L4.24793 16.0019L3.70308 19.4608C3.69245 19.5276 3.69643 19.596 3.71477 19.6611C3.73311 19.7262 3.76538 19.7866 3.80932 19.8381C3.85326 19.8895 3.90784 19.9308 3.9693 19.9591C4.03076 19.9875 4.09763 20.0021 4.1653 20.0021H7.17426C7.31155 20.002 7.4443 19.9529 7.54866 19.8637C7.65302 19.7745 7.72214 19.651 7.74361 19.5154L8.53617 14.4885C8.55765 14.3528 8.62684 14.2292 8.73129 14.14C8.83575 14.0508 8.96861 14.0018 9.10598 14.0018H10.8775C13.6675 14.0018 16.035 11.9664 16.4676 9.20831C16.7747 7.25074 15.7888 5.46938 14.1039 4.58594Z" fill="#0070E0" />
                                        <path d="M3.21566 0.00195312C2.9321 0.00195312 2.69041 0.208224 2.64631 0.488225L0.285108 15.4609C0.240335 15.7451 0.459976 16.0022 0.747774 16.0022H4.24924L5.11866 10.4885L6.06468 4.48844C6.08615 4.35284 6.15529 4.22933 6.25965 4.14012C6.36401 4.05091 6.49675 4.00184 6.63405 4.00173H11.6502C12.5631 4.00173 13.3958 4.21468 14.1048 4.58623C14.1533 2.07513 12.0813 0.00195312 9.23247 0.00195312H3.21566Z" fill="#003087" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_9469_25891">
                                            <rect width="76" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}

                            {platfrom == 'iOS' && (
                                <svg width="42" height="20" viewBox="0 0 42 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7989_67295)">
                                        <path d="M20.7653 3.33203C23.1559 3.33203 24.8036 5.09059 24.8036 7.60773C24.8036 10.1594 23.1236 11.9179 20.7006 11.9179H18.0838V16.366H16.1777V3.33203H20.7653ZM18.0838 10.2283H20.2484C21.896 10.2283 22.8329 9.26285 22.8329 7.64222C22.8329 5.9871 21.896 5.05611 20.2484 5.05611H18.0515V10.2283H18.0838ZM25.2882 13.711C25.2882 12.0558 26.4835 11.0214 28.6157 10.8835L31.071 10.7455V10.0214C31.071 8.95251 30.3926 8.33185 29.2942 8.33185C28.2281 8.33185 27.5819 8.88355 27.4204 9.7111H25.6758C25.7728 7.98703 27.1619 6.71122 29.3588 6.71122C31.5233 6.71122 32.9125 7.91807 32.9125 9.84903V16.4005H31.1679V14.8488H31.1356C30.6187 15.9178 29.488 16.5729 28.325 16.5729C26.5158 16.5729 25.2882 15.4005 25.2882 13.711ZM31.071 12.8489V12.0903L28.8742 12.2283C27.7758 12.2972 27.1619 12.8144 27.1619 13.642C27.1619 14.4695 27.8081 15.0213 28.7773 15.0213C30.0695 15.0213 31.071 14.0903 31.071 12.8489ZM34.5278 19.9176V18.3315C34.657 18.366 34.9801 18.366 35.1093 18.366C35.9493 18.366 36.4016 17.9867 36.6923 17.0212C36.6923 16.9867 36.8539 16.435 36.8539 16.435L33.6232 6.9181H35.5939L37.8554 14.6764H37.8877L40.1491 6.9181H42.0875L38.76 16.9522C37.9846 19.2625 37.1123 19.9866 35.2709 19.9866C35.1416 19.9521 34.6893 19.9521 34.5278 19.9176Z" fill="black" />
                                        <path d="M8.10684 3.60173C8.63542 2.94145 8.99213 2.02384 8.89512 1.10938C8.13129 1.14164 7.20781 1.63508 6.66396 2.29299C6.17128 2.87692 5.74506 3.81185 5.86269 4.70821C6.71208 4.77589 7.57827 4.262 8.10684 3.60173ZM10.3013 9.40488C10.2845 7.42721 11.8672 6.47576 11.9405 6.42854C11.0476 5.09068 9.66278 4.90496 9.16934 4.8845C7.98921 4.76409 6.86408 5.60064 6.26752 5.60064C5.66868 5.60064 4.74749 4.90338 3.76672 4.92463C2.48042 4.94352 1.29418 5.69508 0.631934 6.88105C-0.700961 9.27582 0.293555 12.8125 1.59513 14.7492C2.23217 15.6975 2.9899 16.7631 3.98823 16.7245C4.94914 16.686 5.30967 16.0839 6.47071 16.0839C7.62945 16.0824 7.95713 16.723 8.96922 16.7049C10.0019 16.682 10.6573 15.7369 11.2905 14.7839C12.0185 13.686 12.3202 12.6197 12.3354 12.5662C12.3148 12.552 10.3243 11.7697 10.3013 9.40488Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7989_67295">
                                            <rect width="41.9984" height="19.9993" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            )}

                            {selectedMethod === 'applePay' && (
                                <div className="trial-method__checkmark">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="18" height="18" rx="5" fill="black" />
                                        <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                                    </svg>
                                </div>
                            )}
                        </div>

                        <div
                            className={`trial-method__option ${selectedMethod === 'creditCard' ? 'trial-method__option--active' : ''}`}
                            onClick={() => handleSelectMethod('creditCard')}
                        >
                            Credit Card

                            <div className="trial-method__cards">
                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7989_67307)">
                                        <rect width="22" height="15" rx="2" fill="white" />
                                        <path d="M9.44961 5.05952L8.64341 9.88095H9.94574L10.7519 5.05952H9.44961ZM7.52713 5.05952L6.28682 8.39286L6.16279 7.67857L5.72868 5.53571C5.72868 5.53571 5.66667 5.11905 5.10853 5.11905H3V5.17857C3 5.17857 3.62015 5.29762 4.36434 5.71429L5.48062 9.88095H6.84496L8.95349 5.05952H7.52713ZM16.9535 5.05952C16.4574 5.05952 16.3333 5.41667 16.3333 5.41667L14.4109 9.88095H15.7752L16.0233 9.16667H17.6977L17.8217 9.88095H19L17.9457 5.05952H16.9535ZM16.3953 8.21429L17.0775 6.42857L17.4496 8.21429H16.3953ZM12.6744 6.42857C12.6744 5.89286 13.9767 5.95238 14.5349 6.25L14.7209 5.2381C14.7209 5.2381 14.1628 5 13.5426 5C12.9225 5 11.3721 5.29762 11.3721 6.60714C11.3721 7.85714 13.1705 7.85714 13.1705 8.5119C13.1705 9.16667 11.5581 9.04762 11 8.63095L10.814 9.70238C10.814 9.70238 11.3721 10 12.3023 10C13.1705 10 14.5349 9.58333 14.5349 8.33333C14.4729 7.08333 12.6744 6.96429 12.6744 6.42857Z" fill="#191D79" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7989_67307">
                                            <rect width="22" height="15" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7989_67310)">
                                        <rect width="22" height="15" rx="2" fill="white" />
                                        <path d="M12.7067 4.30078H9.29297V10.6979H12.7067V4.30078Z" fill="#FF5F00" />
                                        <path d="M9.51022 7.49844C9.51074 6.88206 9.64463 6.27386 9.90177 5.71976C10.1589 5.16566 10.5326 4.68014 10.9946 4.29988C10.423 3.82799 9.73644 3.53451 9.01347 3.45296C8.2905 3.37142 7.56026 3.50511 6.90623 3.83876C6.25221 4.1724 5.70078 4.69253 5.31499 5.3397C4.9292 5.98687 4.72461 6.73495 4.72461 7.49844C4.72461 8.26193 4.9292 9.01001 5.31499 9.65718C5.70078 10.3043 6.25221 10.8245 6.90623 11.1581C7.56026 11.4918 8.2905 11.6255 9.01347 11.5439C9.73644 11.4624 10.423 11.1689 10.9946 10.697C10.5318 10.3175 10.1575 9.83218 9.90026 9.2779C9.64303 8.72362 9.50963 8.11502 9.51022 7.49844Z" fill="#EC001B" />
                                        <path d="M17.3127 7.49844C17.3074 8.26391 17.0979 9.01251 16.7081 9.6594C16.3183 10.3063 15.7638 10.8256 15.1073 11.1586C14.4508 11.4915 13.7187 11.6247 12.9938 11.5432C12.2689 11.4616 11.5802 11.1685 11.0059 10.697C11.4682 10.3171 11.8422 9.83164 12.0993 9.27746C12.3565 8.72328 12.4901 8.1149 12.4901 7.49844C12.4901 6.88198 12.3565 6.2736 12.0993 5.71942C11.8422 5.16524 11.4682 4.67979 11.0059 4.29988C11.6923 3.7351 12.5394 3.42866 13.4115 3.42969C15.5684 3.42969 17.3127 5.26063 17.3127 7.49844Z" fill="#F89E1B" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7989_67310">
                                            <rect width="22" height="15" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>


                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7989_67316)">
                                        <rect width="22" height="15" rx="2" fill="white" />
                                        <path d="M4.43234 5.69922L2.59961 9.0223V9.29922H4.12688L4.43234 8.74537H5.65415L5.95961 9.29922H8.09779V7.9146L9.01415 9.29922H9.93052L10.5414 8.46845V9.29922H15.7342L16.6505 8.46845L17.5669 9.29922H19.3996L17.8723 7.36076L19.3996 5.69922H17.5669L16.6505 6.52999L15.7342 5.69922H10.236L9.31961 7.08383L8.40325 5.69922H6.87597L6.57052 7.08383L5.95961 5.69922H4.43234Z" fill="#006FCF" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.03144 5.10156L2 9.89536H4.43192L4.73342 9.13104H5.42252L5.72402 9.89536H8.40092V9.31204L8.63942 9.89536H10.0241L10.2627 9.29969V9.89536H15.8298L16.5067 9.15087L17.1406 9.89536L20 9.90156L17.9622 7.51186L20 5.10156H17.1849L16.526 5.83229L15.9121 5.10156H9.85574L9.33566 6.3389L8.8034 5.10156H6.37658V5.66505L6.10658 5.10156H4.03144ZM4.502 5.78232H5.68742L7.0349 9.03302V5.78232H8.33348L9.37424 8.11303L10.3335 5.78232H11.6256V9.22221H10.8393L10.8329 6.52668L9.68666 9.22221H8.98334L7.83074 6.52668V9.22221H6.21326L5.90666 8.45098H4.25002L3.94402 9.22151H3.07742L4.502 5.78232ZM15.5418 5.78232H12.3448V9.22016H15.4923L16.5067 8.08078L17.4845 9.22016H18.5067L17.021 7.51116L18.5067 5.78232H17.5288L16.5196 6.90858L15.5418 5.78232ZM5.07866 6.3643L4.5329 7.7381H5.62382L5.07866 6.3643ZM13.1343 7.12247V6.4945V6.49392H15.1291L15.9995 7.49817L15.0905 8.50792H13.1343V7.82236H14.8784V7.12247H13.1343Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7989_67316">
                                            <rect width="22" height="15" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7989_67320)">
                                        <rect width="22" height="15" rx="2" fill="white" />
                                        <path d="M22.673 9.5C22.673 9.5 17.1374 13.4033 7 15.1539H22.673V9.5Z" fill="#F48120" />
                                        <path d="M2.72564 5.88672H1.88281V8.84H2.72564C3.17116 8.84 3.49355 8.7328 3.77807 8.50167C4.1164 8.22232 4.31485 7.8035 4.31485 7.36874C4.30967 6.49363 3.66012 5.88672 2.72564 5.88672ZM3.40229 8.10955C3.21978 8.27054 2.98905 8.34586 2.61286 8.34586H2.45745V6.39162H2.61326C2.98387 6.39162 3.20942 6.45617 3.40269 6.62793C3.60154 6.80526 3.71949 7.07903 3.71949 7.36356C3.7191 7.64808 3.60074 7.93261 3.40229 8.10955Z" fill="#231F20" />
                                        <path d="M5.15276 5.88672H4.57812V8.84H5.15276V5.88672Z" fill="#231F20" />
                                        <path d="M6.55997 7.02012C6.21647 6.8914 6.11445 6.80533 6.11445 6.64952C6.11445 6.46142 6.29696 6.32195 6.54403 6.32195C6.71579 6.32195 6.86084 6.39169 7.00589 6.56344L7.30676 6.17132C7.05969 5.95653 6.7644 5.84375 6.43684 5.84375C5.916 5.84375 5.51352 6.20877 5.51352 6.69215C5.51352 7.10022 5.70161 7.30943 6.23839 7.5031C6.46394 7.5836 6.57671 7.63739 6.63569 7.66967C6.74846 7.74499 6.80744 7.85219 6.80744 7.97572C6.80744 8.21721 6.61935 8.39454 6.36192 8.39454C6.08815 8.39454 5.86778 8.25507 5.73389 8.00242L5.36328 8.36226C5.62629 8.75439 5.94868 8.92614 6.38344 8.92614C6.97959 8.92614 7.4036 8.52884 7.4036 7.9542C7.41396 7.4764 7.21511 7.26161 6.55997 7.02012Z" fill="#231F20" />
                                        <path d="M7.58984 7.36729C7.58984 8.23721 8.27168 8.90828 9.14678 8.90828C9.39385 8.90828 9.60864 8.86006 9.86607 8.73653V8.05988C9.63534 8.29061 9.43131 8.38187 9.17348 8.38187C8.59366 8.38187 8.18002 7.96304 8.18002 7.36171C8.18002 6.79265 8.60402 6.34673 9.14638 6.34673C9.42015 6.34673 9.62976 6.44357 9.86567 6.67948V6.00283C9.61342 5.87411 9.40381 5.82031 9.15674 5.82031C8.29319 5.82071 7.58984 6.50812 7.58984 7.36729Z" fill="#231F20" />
                                        <path d="M14.4409 7.87324L13.6515 5.88672H13.0234L14.2743 8.91491H14.586L15.8584 5.88672H15.2355L14.4409 7.87324Z" fill="#231F20" />
                                        <path d="M16.1211 8.84H17.7533V8.34068H16.6957V7.54607H17.7159V7.04635H16.6957V6.39162H17.7533V5.88672H16.1211V8.84Z" fill="#231F20" />
                                        <path d="M20.0372 6.76055C20.0372 6.20743 19.6558 5.89062 18.9903 5.89062H18.1367V8.8439H18.7114V7.65717H18.7867L19.5813 8.8439H20.2902L19.3613 7.59819C19.7957 7.50694 20.0372 7.21165 20.0372 6.76055ZM18.8775 7.24911H18.711V6.35249H18.8883C19.2481 6.35249 19.4414 6.50272 19.4414 6.79283C19.4414 7.08811 19.2481 7.24911 18.8775 7.24911Z" fill="#231F20" />
                                        <path opacity="0.65" d="M11.6338 8.94733C12.5027 8.94733 13.2071 8.24295 13.2071 7.37406C13.2071 6.50516 12.5027 5.80078 11.6338 5.80078C10.7649 5.80078 10.0605 6.50516 10.0605 7.37406C10.0605 8.24295 10.7649 8.94733 11.6338 8.94733Z" fill="url(#paint0_linear_7989_67320)" />
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_7989_67320" x1="12.2662" y1="8.61022" x2="10.6768" y2="5.50366" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F58720" />
                                            <stop offset="0.3587" stop-color="#E16F27" />
                                            <stop offset="0.703" stop-color="#D4602C" />
                                            <stop offset="0.9816" stop-color="#D05B2E" />
                                        </linearGradient>
                                        <clipPath id="clip0_7989_67320">
                                            <rect width="22" height="15" rx="2" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>



                            </div>
                            {selectedMethod === 'creditCard' && (
                                <div className="trial-method__checkmark"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="5" fill="black" />
                                    <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.6" stroke-linecap="round" />
                                </svg></div>
                            )}

                        </div>
                    </div>
                )
            }







            <div className="trial-line">
                <span>Your {week}-week plan with AI promotion tools</span>
                <strong>{yourPrice} USD</strong>
            </div>
            <div className="trial-line" style={{ position: 'relative' }}>
                <span><PriceInfo sessionId={sessionId} eventName={'web_click_trialpricing'} setShowTermBundle={setShowTermBundle} text={'60% price discount'} /></span>
                <strong className='red'>- {discount} USD</strong>
            </div>
            {/* <div className="trial-line">
                <span>Promo code:{promocodeCurrent}</span>
                {promocodeCurrent === '' ? (
                    <strong onClick={() => setShowPromocodePopup(true)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><svg style={{ width: '14rem', height: '14rem', marginRight: '1.5rem' }} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14" height="14" transform="translate(0 0.5)" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.71094 7.50015C1.71094 7.15651 1.98952 6.87793 2.33316 6.87793L11.6665 6.87793C12.0101 6.87793 12.2887 7.15651 12.2887 7.50015C12.2887 7.84379 12.0101 8.12237 11.6665 8.12237L2.33316 8.12237C1.98952 8.12237 1.71094 7.8438 1.71094 7.50015Z" fill="#8F969C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99918 12.7891C6.65553 12.7891 6.37695 12.5105 6.37695 12.1668L6.37695 2.83351C6.37695 2.48986 6.65553 2.21128 6.99917 2.21128C7.34282 2.21128 7.6214 2.48986 7.6214 2.83351L7.6214 12.1668C7.6214 12.5105 7.34282 12.7891 6.99918 12.7891Z" fill="#8F969C" />
                    </svg>
                        Add </strong>
                ) : (
                    <strong className='red'>- 9.58 USD</strong>
                )}

            </div> */}
            <div className="trial-total">
                <div className="trial-total-line">
                    <span>Total:</span>
                    <strong>{total} USD</strong>
                </div>
                <div className="trial-total-bottom">
                    You just saved {discount} USD ({precent}% off)
                </div>
            </div>
            {/* {selectedMethod === 'applePay' && (
                <div style={{ minHeight: "113px", margin: "24rem 0px 24rem", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <div className="trial-method__amount">
                        1.00 <span>USD
                        </span>
                    </div>
                </div>
            )} */}
            <div style={{
                display: 'flex',
                flex: 1,
                alignItems: platfrom == 'iOS' ? 'flex-end' : 'stretch',
                width: '100%'
            }}>

                <div className={`trial-method__credit-card ${selectedMethod === 'creditCard' ? '' : 'hidden'}`} style={{ width: '100%', paddingBottom: '62rem' }}>
                    <div style={{ minHeight: "113px", margin: "0 0px 24rem", flex: platfrom == 'iOS' ? '0' : '1' }}>
                        <Payment
                            merchantData={merchantData}
                            styles={styles}
                            formParams={formParams}
                            onReadyPaymentInstance={handleOnReadyPaymentInstance}
                            onMounted={handleMounted}
                            onSuccess={handleSuccess}
                            onSubmit={handleSubmit}
                            applePayContainerRef={appleContainerRef}
                        // applePayButtonParams={{
                        //     apple_pay_merchant_domain: "HTTPS://zeely.ai/.well-known/apple-developer-merchantid-domain-association",
                        //     buttonType: 'buy',
                        //     buttonColor: 'black',
                        //     containerId: 'yourCustomContainerId'
                        // }}
                        />
                    </div>
                    <div className="block-button" >
                        <WithWizard
                            render={({ next, previous, step, steps }) => (
                                <MyButton buttonText={'Continue'}
                                    // buttonIcon={'lock'}
                                    // onClick={next}
                                    onClick={() => {
                                        mixpanel.track("web_trial", {
                                            session_id: sessionId,
                                            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                                            user_id: localStorage.getItem('userId'),
                                            web_pay_method: 'CreditCard',
                                            web_utm_campaign: localStorage.getItem("utmCampaign"),
                                            web_utm_source: localStorage.getItem("utmSource"),
                                            web_utm_medium: localStorage.getItem("utmMedium"),
                                            web_utm_content: localStorage.getItem("utmContent"),
                                            web_utm_term: localStorage.getItem("utmTerm"),
                                            isDebug: false,
                                            funnel: 'MAIN_v1',
                                        });
                                        localStorage.setItem('web_pay_method', 'CreditCard')
                                        form?.submit();
                                    }}
                                >
                                </MyButton >
                            )}
                        />
                    </div>

                </div>




                <div className={`trial-method__credit-card ${selectedMethod === 'applePay' ? '' : 'hidden'}`} style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {/* <div className="trial-method__amount">
        1.00 <span>USD
        </span>
    </div> */}


                    {isUSA && (
                        <div onClick={() => { console.log('132') }} style={{ borderRadius: '100rem', width: '100%', overflow: 'hidden', height: '62rem', position: "relative", transform: 'none', marginBottom: platfrom == 'iOS' ? '8rem' : '' }}>

                            <div id="paypal-button" style={{ transform: 'scale(2)', width: '100%', zIndex: '0', opacity: '1' }}></div>
                            <MyButton buttonText="Pay with"
                                buttonIcon="PayPal"

                                style={{ position: 'absolute', left: '0', top: 0, zIndex: '1', pointerEvents: 'none' }}
                            // onClick={() => {
                            //     form?.submit();
                            // }}
                            >
                            </MyButton >
                        </div>
                    )}



                    {/* <WithWizard
                            render={({ next, previous, step, steps }) => (
                                <MyButton buttonText="Pay with"
                                    buttonIcon="PayPal"
                                    onClick={next}
                                    style={{ marginBottom: '8rem', transform: 'none' }}
                                // onClick={() => {
                                //     form?.submit();
                                // }}
                                >
                                </MyButton >
                            )}
                        /> */}
                    {platfrom == 'iOS' && (
                        <div style={{ position: 'relative', width: '100%', height: '62rem' }} >
                            <div ref={appleContainerRef} style={{ width: '100%', opacity: '0' }}>

                            </div>
                            <MyButton
                                style={{ position: 'absolute', left: '0', top: 0, zIndex: '1', pointerEvents: 'none' }}
                                buttonText="Pay with"
                                buttonIcon="applePay"
                            // onClick={next}
                            // onClick={() => {
                            //     console.log(form)
                            //     // form?.submit();
                            // }}
                            >
                            </MyButton >
                        </div>
                    )}




                    {/* <WithWizard
                            render={({ next, previous, step, steps }) => (
                                <MyButton buttonText="Pay with"
                                    buttonIcon="applePay"
                                    onClick={next}
                                // onClick={() => {
                                //     form?.submit();
                                // }}
                                >
                                </MyButton >
                            )}
                        /> */}
                </div>

            </div>




            {/* <div className="trial-method__footer">
                <div className="trial-method__rating" style={{ aspectRatio: "151/54", width: '151px' }}>
                    <img src={process.env.PUBLIC_URL + '/img/rating.svg'} alt="" />
                </div>

                <p className="trial-method__company-info">
                    ZEELY INC, 2810 N Church St, Ste 27833, Delaware, the USA
                </p>
            </div> */}
            {showPromocodePopup && (
                <div className="brandname">
                    <div className="brandname-wrapper" style={{ paddingTop: '22rem' }}>
                        <div>
                            <div onClick={() => setShowPromocodePopup(false)} className="brandname-top" style={{ width: '46rem', height: '46rem', marginLeft: '-14rem', marginBottom: '10rem' }}>
                                <svg style={{ width: '46rem', height: '46rem' }} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                                </svg>



                            </div>
                            <CustomTitle style={{ marginBottom: "12rem" }} title="Add your promo code" />
                            <p style={{ marginBottom: "24rem" }} className="text">To make sure that your account is secured</p>
                            <div className={`input `} style={{ marginBottom: "12rem" }}>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.45546 3.97842C9.06468 3.3692 9.28866 3.15262 9.54038 3.00763C9.77885 2.87027 10.0396 2.77587 10.3107 2.72872C10.5969 2.67896 10.9076 2.70196 11.7657 2.77996L13.3213 2.92138C14.0395 2.98667 14.5427 3.03284 14.9367 3.09865C15.3226 3.16311 15.5576 3.23979 15.7445 3.34373C16.1274 3.55669 16.4432 3.87248 16.6562 4.25544C16.7601 4.44234 16.8368 4.67731 16.9013 5.06325C16.9671 5.45727 17.0133 5.96041 17.0785 6.67862L17.22 8.23425C17.298 9.09228 17.321 9.403 17.2712 9.6892C17.2241 9.96033 17.1297 10.2211 16.9923 10.4596C16.8473 10.7113 16.6307 10.9352 16.0215 11.5445L11.8129 15.753C11.2113 16.3546 10.7874 16.7778 10.4297 17.0815C10.0778 17.3802 9.83303 17.5249 9.60464 17.5991C9.13266 17.7525 8.62425 17.7525 8.15226 17.5991C7.92388 17.5249 7.67908 17.3802 7.32719 17.0815C6.96949 16.7778 6.54557 16.3546 5.94396 15.753L4.2469 14.056C3.64529 13.4544 3.22209 13.0304 2.91843 12.6727C2.6197 12.3209 2.47504 12.076 2.40083 11.8477C2.24747 11.3757 2.24747 10.8673 2.40083 10.3953C2.47504 10.1669 2.61969 9.9221 2.91843 9.57021C3.22209 9.21252 3.64529 8.78859 4.2469 8.18698L8.45546 3.97842Z" stroke={error ? '#EF2C4F' : '#CAD4DD'} stroke-width="1.3" />
                                    <circle cx="11.707" cy="8.2932" r="1.5" transform="rotate(45 11.707 8.2932)" fill={error ? '#EF2C4F' : '#CAD4DD'} />
                                </svg>

                                <input
                                    type="text" placeholder='Enter promo code'
                                    value={promocode}
                                    onChange={handleInputChange}
                                    style={{ paddingLeft: '46rem', borderColor: error ? '#EF2C4F' : '', }}
                                    className={` ${error ? "error" : ""
                                        }`}
                                />


                            </div>
                            {/* {errorAi && (
             <div className="error-text">Error</div>
         )} */}



                            <WithWizard
                                render={({ next, previous, step, steps }) => (
                                    <>
                                        <MyButton
                                            // ref={nextPageButtonRef}
                                            // ref={clickedElementRef}
                                            style={{ marginTop: "24rem" }}
                                            buttonStatus={buttonStatus}
                                            buttonTime={"0"}
                                            buttonText={"Continue"}
                                            onClick={() => handleClick()}
                                            className={(areAllCheckboxesUnchecked()) ? (error ? 'error' : 'disable') : undefined}
                                        >
                                        </MyButton>

                                    </>

                                )}
                            />



                        </div>

                    </div>
                </div>
            )}

            {showErrors && (
                <div className="congrats" style={{ zIndex: '2' }}>
                    <div className="congrats-icon">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="44" height="44" rx="22" fill="#EF2C4F" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4271 12C25.5189 11.999 26.5664 12.4318 27.3392 13.2032L30.7873 16.6451C31.56 17.4165 31.9947 18.4632 31.9956 19.5551L32 24.4271C32.001 25.5189 31.5682 26.5664 30.7968 27.3392L27.3549 30.7873C26.5835 31.56 25.5368 31.9947 24.4449 31.9956L19.5729 32C18.4811 32.001 17.4336 31.5682 16.6608 30.7968L13.2127 27.3549C12.44 26.5835 12.0053 25.5368 12.0044 24.4449L12 19.5729C11.999 18.4811 12.4318 17.4336 13.2032 16.6608L16.6451 13.2127C17.4165 12.44 18.4632 12.0053 19.5551 12.0044L24.4271 12ZM20.5881 17.2474C20.5403 16.435 21.1862 15.75 22 15.75C22.8138 15.75 23.4597 16.435 23.4119 17.2474L23.0587 23.2517C23.0257 23.8123 22.5615 24.25 22 24.25C21.4385 24.25 20.9743 23.8123 20.9413 23.2517L20.5881 17.2474ZM22 25.25C21.1716 25.25 20.5 25.9216 20.5 26.75C20.5 27.5784 21.1716 28.25 22 28.25C22.8284 28.25 23.5 27.5784 23.5 26.75C23.5 25.9216 22.8284 25.25 22 25.25Z" fill="white" />
                        </svg>


                    </div>
                    <div className="congrats-info">
                        <strong>Oops, something went wrong</strong>
                        <span>We were unable to make the payment </span>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Trial;
