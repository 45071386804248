import React, { useState, useEffect, useRef } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import MyButton from '../components/UI/button/MyButton';
import MyInputCountry from '../components/UI/input/MyInputCountry';
import { useHistory } from 'react-router-dom';

const Country = ({setButtonShow, setButtonText, buttonStatus, setButtonStatus, setEventProperties, sessionId, setEventName}) => {
    const history = useHistory();
    useEffect(() => {
        if(setButtonShow) {
            setButtonShow(true);
        }
        
        if(setButtonText) {
            setButtonText("Continue");
        }
       
        if(buttonStatus == 'Summary') {
            setButtonText('Save');
        }
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);

    }, []);
    useEffect(() => {
        if(buttonStatus == 'SummaryLoad') {
            setButtonStatus("");
            history.push('/summary');
        }
    }, [buttonStatus]);

    const [isButtonDisabled, setIsButtonDisabled] = useState("true");
    
    const handleInputChange = (value) => {
        setIsButtonDisabled(value === "");
        const companyAddress = value;
        localStorage.setItem('companyAddress', companyAddress);

        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function(profile) {
                profile.companyAddress = value;
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                companyAddress: value
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
    };

    const inputRefs = useRef([]);
    const [code, setCode] = useState(Array(6).fill(''));

    
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim().slice(0, 6);
        if (pastedData.length === inputRefs.current.length) {

            const splitData = pastedData.split('');
            setCode(splitData);
            const lastInputField = inputRefs.current[inputRefs.current.length - 1];
            if (lastInputField) {
                lastInputField.focus();
            }
        }
    };

    const handleChangeCode = (index) => (e) => {
        const newCode = [...code];
        const { value } = e.target;

        if (value === '' && newCode[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        newCode[index] = value.toUpperCase().replace(/[^A-Z0-9]/g, '') || '';
        setCode(newCode);

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
        }
    };

    const isFormComplete = () => {
        return code.every(digit => digit.trim() !== '');
    };

    return (
        <div style={{ paddingBottom: "0rem" }}>
            <CustomTitle style={{ marginBottom: "24rem" }} title="Where’s your business located?" />
            {/* <p className="text" style={{ marginBottom: "24rem" }}>Choose the country or city where our AI
should look for your customers</p> */}
                {/* <div className="code-inputs">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                type="tel"
                                maxLength="1"
                                value={digit}
                                onKeyDown={handleKeyDown(index)}
                                onChange={handleChangeCode(index)}
                                onPaste={handlePaste}
                                ref={(el) => inputRefs.current[index] = el}
                                className="form__input"
                            />
                        ))}
                    </div> */}
            <MyInputCountry setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} onChange={handleInputChange} placeholder='Search by country or state'></MyInputCountry>
            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    buttonText="Continue"
                    style={{ marginTop: "24rem" }}
                    onClick={next}>Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </div>
    );
}

export default Country;
