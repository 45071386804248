import React, { useState, useEffect } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import mixpanel from 'mixpanel-browser';
const Testing = ({ setButtonShow, setButtonTime, setBlockPaddingBottom, setButtonStatus, setAiShow, setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonShow(false)
        setButtonTime(0);

        setBlockPaddingBottom('32rem')
        setButtonStatus('')
        setAiShow('')

        setEventName('web_rezults')

    }, []);

    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioClick = (value, nextFunction) => {
        setSelectedRadio(value);
        mixpanel.track('web_rezults', {
            session_id: sessionId,
            web_rezults_answer: value,
            web_entrance: 'signup',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });
        setTimeout(() => {
            nextFunction();
        }, 350);
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Ever analyzed your creatives’ performance?" />

            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'Yes'} onClick={() => handleRadioClick('Yes', next)} name='do' style={{ height: '42rem' }}>
                            Yes
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'Not sure'} onClick={() => handleRadioClick('Not sure', next)} name='do' style={{ height: '42rem' }}>
                            Not yet
                        </CustomRadio>
                    </>
                )}
            />

        </>
    );
}

export default Testing;
