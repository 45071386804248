import React, { useState, useEffect } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import mixpanel from 'mixpanel-browser';
const Create = ({ setButtonShow, setButtonTime, setSelectedOption,setAiShow, setButtonStatus,setBlockPaddingBottom, setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonShow(false)
        setButtonTime(0);
        setSelectedOption('1');
        setAiShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setEventName('web_haveyouever_3')
      
    }, []); 
   
    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioClick = (value, nextFunction) => {
        setSelectedRadio(value);
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
           
        })
        mixpanel.track('web_haveyouever_3', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_haveyouever_3_answer: value,
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
        setTimeout(() => {
            nextFunction();
        }, 350);
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Have you ever created or ordered UGC video ads?" />
            
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'Yes'}  onClick={() => handleRadioClick('Yes', next)} name='do'  style={{ height: '42rem' }}>
                        Yes
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'Not yet'}  onClick={() => handleRadioClick('Not yet', next)} name='do'  style={{ height: '42rem' }}>
                        Not yet
                        </CustomRadio>
                    </>
                )}
            />
        </>
    );
}

export default Create;
